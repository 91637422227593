<template>
    <ion-page>
        <ion-content :fullscreen="true" style="background: #000">
            <div class="control-head">
                <div class="return" on-touch="returnSelect" @click="returnSelect"><ion-icon src="/assets/icon/arrow-back.svg"></ion-icon></div>
            </div>
            <ion-grid style="margin: 0;padding: 0;">
                <iframe :src="toUrl" style="width: 100%; height: 100vh;border:0"></iframe>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonGrid, IonContent, IonPage, IonIcon } from '@ionic/vue';
import { useRoute } from 'vue-router';
import {BASE_SITE_URL} from "../constant/api";

export default  {
    name: 'AddNews',
    components: { IonGrid, IonContent, IonPage, IonIcon },
    setup() {
        const route = useRoute();
        return { route };
    },
    data() {
        return {
            toUrl: '',
        }
    },
    created() {
        this.toUrl = BASE_SITE_URL + '/';
        if(this.route.params.type == 3) {
            this.toUrl += 'news/';
        } else {
            this.toUrl += 'video/';
        }
        this.toUrl += 'new-post.html?edit=1&oksd342kjguser=' + localStorage.getItem('user');
    },
    methods: {
        returnSelect() {
            window.location.href = '/select';
        },
    }
}
</script>

<style>
.control-head {
    position: fixed;
    bottom: 15px;
    z-index: 999;
    left: 15px;
    font-size: 36px;
    background: #0037ffad;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 4px;
}
</style>
